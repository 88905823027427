@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap');

* html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@layer base {
  html {
    font-family: 'Libre Baskerville', serif;
  }

  h1, h2, h3, h4, h5, h6, button {
    font-family: 'Poppins', sans-serif;
  }
}